<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > 馆藏精品</div>
			<el-carousel :interval="5000" arrow="always">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<div class="banner" @click="toBannerDetail(item,index)"><img :src="item.pic"/></div>
				</el-carousel-item>
			</el-carousel>
			<div class="wapper">
				<div class="condition flex_a">
					分类
					<el-select v-model="collectBoutiqueClassId" @change="changeSearch" placeholder="" clearable>
						<el-option
						v-for="item in classifyItem"
						:key="item.id"
						:label="item.name"
						:value="item.id">
						</el-option>
					</el-select>
					关键词
					<el-input v-model="keyword" clearable></el-input>
					<img @click="toSearch" src="@/assets/images/shaixuan.png" alt="">
				</div>
				<el-row :gutter="20">
					<el-col :span="8" v-for="(item,index) in list" :key="index" >
						<div class="imgItem" @click="toDetail(item.id)">
							<img :src="item.cover"/>
							<div class="info">
								<p class="line1">{{item.title}}</p>
								<p class="line1">{{item.intro}}</p>
							</div>
						</div>
					</el-col>
				</el-row>
				<img class="noData" v-if="total == 0" src="../../assets/images/zanwujilu.png"/>
				<el-pagination
					background
					v-if="total > 0"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-size="limit"
					layout="total, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
				
			</div>
				
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				classifyItem:[],
				keyword: '',
				classify: '',
				list: [],
				page: 1,
				limit: 9,
				total: null,
				collectBoutiqueClassId: '',
				bannerList: [],
			}
		},
		watch: {
			"$store.state.gcIndex": {
				handler(newVal,oldVal) {
					this.tabIndex = newVal
					if(this.tabIndex == -1){
						this.collectBoutiqueClassId = ''
						this.getClaaifyList()
					}else{
						this.getClaaifyList()
					}
				},
				deep: true,
				immediate: true,
		

			}
		},
		created() {
			if(this.$route.query.id){
				this.collectBoutiqueClassId = this.$route.query.id
			}
			
		},
		mounted() {
			this.getBanner()
		},
		methods: {
			getBanner() {
				let formData = new FormData()
					formData.append('type', 1)
				this.$http({
					url: `/bannersApi/queryBannersPage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.bannerList = res.data
					}
				});
			},
			toSearch() {
				this.page = 1
				this.getList()
			},
			getClaaifyList() {
				this.$http({
					url: `/collectBoutiqueClassApi/queryAllCollectBoutiqueClass`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.classifyItem = res.data
						this.classifyItem.unshift({name:'全部',id:''})
						if(this.tabIndex != -1){
							this.collectBoutiqueClassId = this.classifyItem[this.tabIndex].id
						}
						this.keyword = ''
						this.page = 1
						this.getList()
					}
					
				});
			},
			changeSearch(val) {
				// let proNum = this.classifyItem.findIndex((item, index) =>{
				// 	if(item.id == val){
				// 		return index+1
				// 	}
				// })

				// sessionStorage.setItem('gcIndex',proNum)
				// this.$store.commit('SET_GC',proNum)
				this.collectBoutiqueClassId = val
				this.page = 1
				this.getList()
			},
			getList() {
				let formData = new FormData()
					formData.append('page', this.page)
					formData.append('limit', this.limit)
					formData.append('collectBoutiqueClassId', this.collectBoutiqueClassId)
					formData.append('title', this.keyword)
				this.$http({
					url: `/collectBoutiqueApi/queryCollectBoutiquePage`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.total = res.count
						this.list = res.data
					}
					
				});
			},
			toBannerDetail(item,index) {
				if(item.includeContent == 1){
					this.$router.push({ path: "/boutique/bannerDetail",query:{index:index}});
				}
			},
			toDetail(id) {
				this.$router.push({ path: "/boutique/detail",query:{id:id}});
			},
			handleSizeChange(val) {
				this.limit = val
				this.getList()
			},
			handleCurrentChange(val) {
				this.page = val
				this.getList()
			}
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		background: #FFF;
		.banner{
			img{
				height: 400px;
				width: 100%;
			}
		}
		.wapper{
			padding: 30px 60px;
			.condition{
				font-size: 16px;
				color: #595E63;
				margin-bottom: 10px;
				.el-input{
					width: 160px;
					margin-left: 20px;
				}
				.el-select{
					width: 160px;
					margin-left: 20px;
					margin-right: 30px;
				}
				img{
					width: 38px;
					height: 38px;
					margin-left: 20px;
					cursor: pointer;
				}
			}
			.el-col{
				margin-top: 20px;
			}
			.imgItem{
				cursor: pointer;
				font-size: 16px;
				img{
					width: 100%;
					height: 240px;
				}
				&:hover{
					.info{
						background: #A5343C;
					}
				}
				.info{
					background: #353535;
					color: #FFF;
					padding: 8px 15px;
					p{
						margin-bottom: 5px;
						&:last-of-type{
							margin: 0;
						}
					}
				}
			}
			

		}
	}
	
	
	
}
	
</style>
